<template>
  <div>
    <MainHeader />
    <main>
      <div class="relative w-full pb-20 lg:pt-20">
        <div class="flex flex-col lg:flex-row aic jcb w-full lg:w-10/12 xl:w-7/12 min-container mx-auto lg:pb-20 relative px-4 sm:px-16 lg:px-0">
          <div class="flex flex-col w-full lg:w-1/2 space-y-16 md:pr-12 xl:pr-0 order-2 lg:order-1">
            <h2 class="merienda-bold text-lg md:text-2xl text-brown text-center md:text-center lg:text-left mt-10 lg:mt-0 uppercase w-10/12 sm:w-full mx-auto">brötchen lieferservice in der nähe</h2>
            <p class="text-gray-600 text-center px-2 md:px-0 md:text-center lg:text-left">
              Qualität ofenfrische Brötchen und Backwaren – direkt zu Ihnen an Ihre Haustür! Unser Brötchenservice und online bestellung machts möglich.Was gibt es Schöneres,
              als den Tag mit einem leckeren Frühstück zu beginnen? Mit frischen Croissant, leckeren Brezel und duftenden Brötchen an die Haustür.
              <br>
              <br>
              wir backen Brötchen in bester Qualität und verpacken die Backwaren dort direkt frisch aus dem Ofen heraus.
              Am Wochenende das Frühstück mit der Familie zu genießen, ohne vorher das Haus verlassen zu müssen.
              <br>
              <br>
              Noch während Sie schlafen, hängen wir am Liefertag Ihre Brötchen an die Haustür.
              So können Sie die Backwaren und unseren Service testen.Und das alles, ohne einen Schritt vor die Tür zu setzen!
              <br>
              <br>
              Verwöhnen Sie sich und lassen Sie sich Ihre ofenfrischen Brötchen, belegte brötchen, Croissant, Brot und Kuchen von unseren Brötchenlieferservice einfach nachhause liefern.
              <br>
              <br>
              Denn Zeit ist heutzutage im oft stressigen Alltag ein wahres Geschenk.
              <br>
              <br>
              Gönnen Sie sich diesen kleinen Luxus am Morgen und bezahlen Sie einfach monatlich ganz bequem per Überweisung oder Lastschrift.
            </p>
          </div>
          <div class="flex flex-col jcc aic w-10/12 md:w-1/2 xl:w-3/5 lg:mt-12 md:mt-0 order-1 lg:order-2 lg:pl-8">
            <img
              src="/img/uber_uns.webp"
              alt="Uber-uns"
              class="w-full"
              width="600"
              height="444"
            >
          </div>
        </div>
      </div>

      <div class="relative w-full bg-gray-50 py-10">
        <div class="flex flex-col xl:flex-row aic jcb w-full md:w-10/12 xl:w-7/12 min-container mx-auto py-20 md:py-24 lg:py-28 relative">
          <div class="flex flex-col jcc aie w-full sm:w-10/12 md:w-9/12 xl:w-6/12 px-4 lg:pl-0 lg:pr-12">
            <img
              src="img/bestell-1.webp"
              alt=""
              class="w-1/2 h-64 img-shadow object-cover"
              width="350"
              height="287"
            >
            <img
              src="img/bestell-2.webp"
              alt=""
              class="w-1/2 h-64 img-shadow object-cover self-start"
              width="350"
              height="287"
            >
            <img
              src="img/bestell-4.webp"
              alt=""
              class="w-1/2 h-64 img-shadow object-cover"
              width="350"
              height="287"
            >
          </div>

          <div class="flex flex-col md:flex-row xl:flex-col w-full sm:w-10/12 md:w-full xl:w-6/12 jcb px-4 lg:pl-12 lg:pr-0 md:space-x-10 xl:space-x-0">
            <div class="flex ais jcb md:pt-16 lg:pt-0 md:w-6/12 xl:w-full">
              <div class="flex flex-col items-center md:items-start jcb space-y-12">
                <h2 class="merienda-bold text-lg md:text-2xl text-brown text-center md:text-left mt-16 md:mt-0 lg:mt-16 xl:mt-0">SO EINFACH FUNKTIONIERT ES</h2>
                <p class="text-gray-600 text-center md:text-left">
                  Sie bestellen ganz bequem über unsere Website und wir liefern Ihnen die gewünschten Backwaren an Ihre Haustür.<br>
                  Wenn Sie noch kein Kunde sind und unseren brötchenservice testen möchten, laden wir Sie gerne ein, unseren fantastischen Service zu testen.<br>
                  Um mehr über unser Sortiment und die Bäckereien zu erfahren, von denen wir die Backwaren beziehen, werfen Sie bitte einen Blick auf den Link.
                </p>
                <button
                  class="fcc btn-brown-light px-12 h-60p shadow-xl nunitoSans-bold"
                  @click="showProbeModal = true"
                  ref="Btn"
                >
                  KUNDE WERDEN
                </button>

              </div>

            </div>
            <div class="flex flex-col ais jcb space-y-12 pt-16 w-full md:w-6/12 xl:w-10/12">
              <div class="flex flex-col items-center md:items-start w-full">
                <h2 class="text-xl merienda-bold text-brown">
                  PRÜFEN
                </h2>
                <p class="pt-6 text-base text-gray-500 works-text-2 text-center md:text-left">
                  Klicken Sie auf „Kunde werden“ und prüfen Sie, ob unser Service in Ihrer Region verfügbar ist.
                </p>
              </div>
              <div class="flex flex-col items-center md:items-start w-full">
                <h2 class="text-xl merienda-bold text-brown">
                  BESTELLEN
                </h2>
                <p class="pt-6 text-base text-gray-500 works-text-2 text-center md:text-left">
                  Wählen Sie Ihre Lieblingsbackwaren und die gewünschten Liefertage aus. Legen Sie danach Ihr persönliches Kundenkonto an, um Ihre Bestellung abzuschließen.
                </p>
              </div>
              <div class="flex flex-col items-center md:items-start w-full">
                <h2 class="text-xl merienda-bold text-brown">
                  GENIESSEN
                </h2>
                <p class="pt-6 text-base text-gray-500 works-text-2 text-center md:text-left">
                  Im Normalfall* dürfen Sie sich dann schon in Kürze frühmorgens auf Ihre frischen Backwaren von Brotchen Shop freuen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="relative w-full">
        <div class="flex flex-col lg:flex-row aic jcb w-full lg:w-10/12 xl:w-7/12 min-container mx-auto lg:pt-20 pb-20 lg:pb-0 relative px-4 sm:px-16 lg:px-0">
          <div class="flex flex-col jcc aic w-9/12 md:w-6/12 xl:5/12 mt-12 md:mt-0 lg:pr-8">
            <img
              src="/img/probe.webp"
              alt="Uber-uns"
              class="w-full"
              width="600"
              height="444"
            >
          </div>
          <div class="flex flex-col w-full lg:w-1/2 space-y-16 md:pl-12 xl:pl-0 items-center lg:items-start">
            <h2 class="merienda-bold text-lg md:text-2xl text-brown text-center md:text-center lg:text-left mt-10 lg:mt-0 uppercase w-10/12 sm:w-full mx-auto">GRATIS PROBIEREN</h2>
            <p class="text-gray-600 text-center px-2 md:px-0 md:text-center lg:text-left">
              Fordern Sie jetzt einmalig Ihre Probelieferung an – kostenlos und unverbindlich.
              Wir prüfen die Verfügbarkeit in Ihrer Region und liefern Ihnen ausgewählte
              Backwaren zum Probieren.
            </p>
            <button
              class="btn-brown-light px-12 h-60p shadow-xl nunitoSans-bold"
              @click="showProbeModal = true"
              ref="Btn"
            >
              JETZT TESTEN
            </button>
          </div>
        </div>
      </div>

      <div class="relative w-full pt-32 md:pb-12">
        <div class="flex flex-col w-full md:w-11/12 lg:w-10/12 xl:w-8/12 min-container mx-auto">
          <h2 class="merienda-bold text-2xl text-brown subtitle mb-5 md:mb-12 lg:mb-20 mx-auto uppercase">Ihre Vorteile</h2>
          <div class="flex flex-wrap new-vorteile jcb w-full">
            <div class="w-full sm:w-1/2 lg:w-1/4 p-3">
              <div class="vorteile h-full w-full flex flex-col ais jcs v-color-1 p-6 shadow-2xl">
                <img
                  src="img/vt-1.webp"
                  alt="#"
                  class="opacity-80 w-50p mb-2"
                  width="200"
                  height="200"
                >
                <span class="nunitoSans-bold text-xl text-gray-900 mb-5">Natürlich backfrisch</span>
                <p class="text-gray-700 vt-text text-sm">
                  Ihre backwaren kommen ofenfrisch aus der backstube einer HandswerksBäckerei in Ihrer Region.
                  Handwerk vom Traditionsbäcker - das schmeckt.
                </p>
              </div>
            </div>
            <div class="w-full sm:w-1/2 lg:w-1/4 p-3">
              <div class="vorteile h-full w-full flex flex-col ais jcs v-color-2 p-6 shadow-2xl">
                <img
                  src="img/vt-2.webp"
                  alt="#"
                  class="opacity-80 w-50p mb-2"
                  width="200"
                  height="200"
                >
                <span class="nunitoSans-bold text-xl text-gray-900 mb-5">Günstig wie beim Bäcker</span>
                <p class="text-gray-700 vt-text text-sm">
                  Brötchen so günstig wie beim Bäcker - geringe Liefergebühr.
                  Sie können alle zusätzlichen Transportgebühren sparen.
                </p>
              </div>
            </div>
            <div class="w-full sm:w-1/2 lg:w-1/4 p-3">
              <div class="vorteile h-full w-full flex flex-col ais jcs v-color-3 p-6 shadow-2xl">
                <img
                  src="img/vt-3.webp"
                  alt="#"
                  class="w-50p mb-2"
                  width="200"
                  height="200"
                >
                <span class="nunitoSans-bold text-xl text-gray-900 mb-5">Mehr Zeit</span>
                <p class="text-gray-900 vt-text text-sm">
                  Sie ersparen sich den weg zum bäcker und können Ihr Frühstück backfrisch
                  genießen und Sie können mehr Zeit beim Frühstück mit Ihrer lieben Familie genießen.
                </p>
              </div>
            </div>
            <div class="w-full sm:w-1/2 lg:w-1/4 p-3">
              <div class="vorteile h-full w-full flex flex-col ais jcs v-color-4 p-6 shadow-2xl">
                <img
                  src="img/vt-4.webp"
                  alt="#"
                  class="w-50p mb-2"
                  width="200"
                  height="200"
                >
                <span class="nunitoSans-bold text-xl text-white mb-5">Regelmässig und flexibel</span>
                <p class="text-white vt-text text-sm">
                  Wir liefern frühmorgens. An jedem von Ihnen gewünschten Tag. Auch am Wochenende.
                  Sie können Ihre regelmäßige Lieferung jederzeit anpassen oder unterbrechen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="relative w-full pb-12 overflow-x-hidden">
        <div class="flex flex-col aic jcb w-full md:w-10/12 xl:w-7/12 min-container mx-auto py-20 lg:py-40 relative">
          <h2 class="merienda-bold text-2xl text-brown subtitle mx-auto uppercase mb-28">Neueste Beiträge</h2>
          <div class="blog-area flex jcb px-4 w-full">

            <div
              class="flex flex-col bl-in relative"
              v-for="(blog, i) in blogs"
              :key="i"
            >
              <router-link :to="`/blogs/${blog.id}`">
                <div class="flex flex-col jcs relative w-full flex-1">
                  <div class="flex flex-col jcs flex-1 bl-in-top">
                    <h2 class="a-brown-dark text-xl mb-8 nunitoSans-bold">{{blog.title}}</h2>
                    <p
                      class="text-base leading-7 text-gray-600"
                      v-html="blog.text.substr(0, 130) + ' . . .'"
                    ></p>
                    <span class="text-sm b mt-auto ml-auto text-brown-light">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-10 w-10"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                    </span>
                  </div>
                  <div class="flex flex-col w-full bl-in-img">
                    <img
                      :src="storageUrl + '' + blog.image"
                      :alt="blog.title"
                      class="w-full h-300p object-cover shadow-2xl mb-4"
                      width="800"
                      height="450"
                    >
                    <div class="flex w-20p aic jcb text-sm ">
                      <div class="text-gray-900 relative inline-block mr-2">
                        <svg
                          width="24"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                        >
                          <path d="M0 12v1h23v-1h-23z" />
                        </svg>
                      </div>
                      <span class="whitespace-nowrap dmSans-medium fs-11 categories-in">{{blog.created_at.split('T')[0]}}</span>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col lg:flex-row aic jcc fl-container mx-auto insta md:mt-24 lg:mt-0 mb-32 sm:mb-60 lg:mb-0 pt-20 space-y-12 lg:space-y-0">
        <div class="flex flex-col w-full lg:w-6/12 jcb h-full space-y-16">
          <div class="flex relative px-4">
            <svg
              width="404"
              height="304"
              class=" hidden lg:block absolute -left-20 -bottom-20 z-0"
              fill="none"
              viewBox="0 0 404 304"
            >
              <defs>
                <pattern
                  id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="304"
                fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
              />
            </svg>
            <a
              href="https://www.instagram.com/p/CZjjKTDAnfK/"
              class="w-full md:w-10/12 lg:w-full mx-auto instabox instabox-width--2 shadow-2xl"
              target="_blank"
              rel="noopener"
            >
              <div class="imgBox">
                <img
                  src="img/insta-5.webp"
                  alt="brotchen-shop"
                  class="w-full h-full object-cover"
                  width="800"
                  height="379"
                >
              </div>
              <div class="content fs-52 flex flex-col">
                <i class="fa fa-instagram"></i>
              </div>
            </a>
            <div class="absolute right-0 lg:-right-20 -top-20 z-0 bg-gray-500 bg-opacity-5 w-300p h-300p"></div>
          </div>
          <div class="flex flex-col jcc relative text-center space-y-3 h-full">
            <h2 class="merienda-bold text-lg md:text-2xl text-brown subtitle mx-auto uppercase">Von Instagram</h2>
            <span class="tracking-2xl text-gray-700 text-sm merienda-regular">
              @brotchenshop
            </span>
          </div>

        </div>
        <div class="flex aic w-full lg:w-6/12 px-4">
          <div class="flex flex-col aie w-1/2 space-y-8 lg:space-y-16">
            <div class="flex my-1">
              <a
                href="https://www.instagram.com/p/CZjjU8bgxoH/"
                class="w-full instabox shadow-2xl insta-box-1"
                target="_blank"
                rel="noopener"
              >
                <div class="imgBox w-full h-220p">
                  <img
                    src="img/insta-8.webp"
                    alt="brotchen-shop"
                    class="w-full object-cover"
                    width="700"
                    height="467"
                  >
                </div>
                <div class="content text-4xl flex flex-col">
                  <i class="fa fa-instagram"></i>
                </div>
              </a>
            </div>
            <div class="flex my-1">
              <a
                href="https://www.instagram.com/p/CZji-iMgpYg/"
                class="w-full instabox insta-box-2 shadow-2xl"
                target="_blank"
                rel="noopener"
              >
                <div class="imgBox">
                  <img
                    src="img/insta-7.webp"
                    alt="brotchen-shop"
                    class="w-full object-cover"
                    width="500"
                    height="333"
                  >
                </div>
                <div class="content text-4xl flex flex-col">
                  <i class="fa fa-instagram"></i>
                </div>
              </a>
            </div>
          </div>
          <div class="flex flex-col w-1/2 pl-8 lg:pl-16">
            <div class="flex my-1">
              <a
                href="https://www.instagram.com/p/CZjjcNhg1Pu/"
                class="w-full instabox insta-box-3 shadow-2xl"
                target="_blank"
                rel="noopener"
              >
                <div class="imgBox">
                  <img
                    src="img/insta-6.webp"
                    alt="brotchen-shop"
                    class="w-full object-cover"
                    width="600"
                    height="427"
                  >
                </div>
                <div class="content text-4xl flex flex-col">
                  <i class="fa fa-instagram"></i>
                </div>
              </a>
            </div>
          </div>
        </div>

      </div>

      <div class="flex aic h-150p w-full mb-20 lg:mb-0">
        <div class="flex flex-col md:flex-row items-start jcb w-11/12 xl:w-9/12 min-container mx-auto space-y-8 lg:space-y-0">
          <div class="flex flex-col ais space-y-4 w-full md:w-5/12">
            <span class="merienda-bold text-base md:text-xl text-brown">NEWSLETTER ABONNEMENT</span>
            <p class="text-gray-500 max-w-lg text-sm md:text-base">Abonnieren Sie unseren Newsletter und erhalten Sie die neusten Informationen und Angebote aus dem Brötchenshop.</p>
          </div>
          <form
            @submit.prevent="sendNewsletter"
            class="flex aic jcb w-full md:w-6/12 max-w-2xl shadow-xl"
          >
            <input
              type="text"
              class="w-9/12 md:w-8/12 h-70p p-5 bg-gray-50 text-sm"
              placeholder="Geben Sie Ihre email adresse"
              v-model="email"
              required
            >
            <button
              type="submit"
              class="fcc btn-brown-lighter w-3/12 md:w-4/12 h-70p text-base merienda-bold"
              aria-label="ABONNEMENT"
            >
              <span class="hidden lg:block text-sm">ABONNEMENT</span>
              <svg
                class="lg:hidden"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M24 0l-6 22-8.129-7.239 7.802-8.234-10.458 7.227-7.215-1.754 24-12zm-15 16.668v7.332l3.258-4.431-3.258-2.901z" />
              </svg>
            </button>
          </form>
        </div>
      </div>

    </main>
    <MainFooter />
    <Probe
      :showProbeModal="showProbeModal"
      @close="showProbeModal = false"
    >
    </Probe>
  </div>
</template>

<script>
import MainHeader from "../components/MainHeader.vue";
import MainFooter from "../components/MainFooter.vue";
import Probe from "../components/Probe.vue";
export default {
  components: { MainHeader, MainFooter, Probe },
  data() {
    return {
      showProbeModal: false,
      email: null,
    };
  },
  methods: {
    sendNewsletter() {
      this.$store
        .dispatch("restaurant/Newsletter", { email: this.email })
        .then((res) => {
          this.email = null;
          this.$store.commit("setshowNotif", {
            show: true,
            msg: res.msg,
            type: res.type,
          });
        });
    },
  },
  mounted() {
    if (this.$route.query.type == "probe") {
      this.$refs.Btn.click();
    }
  },
  computed: {
    blogs() {
      let blogs = this.$store.state.orderiomApiPackage.restaurant.blogs;
      return blogs.reverse().slice(0, 2);
    },
    storageUrl() {
      return process.env.VUE_APP_STORAGE_URL;
    },
  },
  created() {
    this.$store.dispatch("restaurant/getBlogs");
  },
};
</script>

