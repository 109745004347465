<template>
  <div>
    <header class="w-full min-h-screen">
       <div class="main-container flex flex-col items-center justify-between relative">

          <div class="flex flex-col md:flex-row aic jcb w-full md:h-32 absolute top-0 left-0 z-50">
            <div class="hidden md:block absolute left-0 top-12 w-full border-b h-10p"></div>
            <nav class="hidden md:flex ais jcs h-full z-1 merienda-regular">
              <router-link
                to="/"
                class="md:text-xs xl:text-sm a-secondary md:px-5 xl:px-6 relative h-full flex aie jcc pb-5"
                :class="activeRoute('/') ? 'bg-brown-lighter shadow-2xl' : ''"
              >
                <h3 class="uppercase">{{$t('Home')}}</h3>
              </router-link>
              <router-link
                to="/about"
                class="md:text-xs xl:text-sm whitespace-nowrap a-secondary md:px-5 xl:px-6 relative h-full flex aie jcc pb-5"
                :class="activeRoute('/about') ? 'bg-brown-lighter shadow-2xl' : ''"
              >
                <h3 class="uppercase">{{$t('About us')}}</h3>
              </router-link>
              <router-link
                to="/services"
                class="md:text-xs xl:text-sm a-secondary md:px-5 xl:px-6 relative h-full flex aie jcc pb-5"
                :class="activeRoute('/services') ? 'bg-brown-lighter shadow-2xl' : ''"
              >
                <h3 class="uppercase">{{$t('How it works')}}</h3>
              </router-link>
              <router-link
                to="/blogs"
                class="md:text-xs xl:text-sm a-secondary md:px-5 xl:px-6 relative h-full flex aie jcc pb-5"
                :class="activeRoute('/blogs') ? 'bg-brown-lighter shadow-2xl' : ''"
              >
                <h3 class="uppercase">{{$t('Blogs')}}</h3>
              </router-link>
              <button
                class="md:text-xs xl:text-sm a-secondary md:px-5 xl:px-6 relative h-full flex aie jcc pb-5"
                @click="showContact = true"
              >
                <h3 class="uppercase">{{$t('Contact')}}</h3>
              </button>
            </nav>
            <div class="flex flex-col items-center md:items-end space-y-4 mt-6 lg:mt-8">
              <div class="hidden md:flex aic jcs lg:space-x-4">
                <router-link
                  to="/dashboard"
                  class="flex aic text-secondary text-sm merienda-regular"
                >
                  <!-- <small>Rayan Khaleghi</small> -->
                  <small class="whitespace-nowrap">{{$t('Your Account')}}</small>
                  <div class="ml-2 lg:ml-4 text-brown-lightest">
                    <svg
                      class="h-4 w-4"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M19 7.001c0 3.865-3.134 7-7 7s-7-3.135-7-7c0-3.867 3.134-7.001 7-7.001s7 3.134 7 7.001zm-1.598 7.18c-1.506 1.137-3.374 1.82-5.402 1.82-2.03 0-3.899-.685-5.407-1.822-4.072 1.793-6.593 7.376-6.593 9.821h24c0-2.423-2.6-8.006-6.598-9.819z" />
                    </svg>
                  </div>
                </router-link>
                <!-- <lang-switcher></lang-switcher> -->
              </div>
              <img
                src="img/logo.webp"
                alt="logo"
                class="hidden md:block w-20 lg:w-24"
                width="300"
                height="220"
              >
            </div>
            <img src="/img/logo.webp" alt="logo" class="w-20 md:hidden mt-8 sm:mt-4" width="300" height="220">
          </div>
       </div>
        <Swiper
          :options="swiperOption"
          navigation
          class="hero-slider hero-style w-full relative"
        >
          <swiper-slide>
            <div class="slide-inner slide-bg-image relative">
                <div class="h-full w-full flex flex-col aic jcc swiper-in">
                  <div class="flex flex-col lg:flex-row items-center justify-center w-full">
                    <img src="/img/slider-1.webp" alt="Slider" class="w-7/12 sm:w-5/12 lg:w-4/12 mb-10 lg:mb-0" width="860" height="755">
                    <div class="flex flex-col aic jcs text-brown-light">
                        <div data-swiper-parallax="300" class="slide-title">
                          <h1 class="text-xl md:text-2xl lg:text-3xl xl:text-4xl merienda-bold text-brown-light mb-1 md:mb-3 whitespace-nowrap">BRÖTCHEN SHOP</h1>
                        </div>
                        <div data-swiper-parallax="400" class="slide-text">
                          <h2 class="text-3xl italianno-regular text-gray-500 subtitle lg:ml-32 mb-auto whitespace-nowrap">Dein leckerer Morgen</h2>
                        </div>
                        <button
                          class="probe-btn-top aic jcc btn-brown-lighter h-12 w-48 shadow-xl uppercase merienda-bold text-sm -mr-60 mt-8"
                          @click="showProbeModal = true"
                        >
                          {{$t('Try For Free')}}
                        </button>
                    </div>
                  </div>
                  <div class="main-container hidden lg:flex aie jcb space-x-6">
                      <div class="flex flex-col w-full max-w-lg text-gray-700 merienda-regular text-sm">
                        Wir liefern Ihnen morgens frische Brötchen.
                        Mit unserem Service können Sie Ihre eigene Bäckerei in Ihrem Zuhause haben.            
                      </div>
                      <div class="flex w-full border-b-2 border-gray-200"></div>
                      <div class="flex jcs space-x-1">
                        <a href="https://www.facebook.com/Broetchenshop-106207038647892"
                          target="_blank"
                          rel="noopener"
                          class="social-items a-brown-light text-3xl opacity-90 hover:opacity-100"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"/>
                          </svg>
                        </a>
                        <a href="https://www.instagram.com/accounts/login/?next=/broetchen.shop/"                          
                          target="_blank"
                          rel="noopener"
                          class="social-items a-brown-light text-3xl opacity-90 hover:opacity-100"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                              <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                          </svg>
                        </a>
                        <a href="https://twitter.com/orderiom"
                          target="_blank"
                          rel="noopener"
                          class="social-items a-brown-light text-3xl opacity-90 hover:opacity-100"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                              <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/>
                          </svg>
                        </a>
                        <a href="https://www.linkedin.com/in/broetchen-shop-080881230/"
                          class="social-items a-brown-light text-3xl opacity-90 hover:opacity-100"
                          target="_blank"
                          rel="noopener"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                              <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/>
                          </svg>
                        </a>
                      </div>
                  </div>
                  <button
                    class="probe-btn-mid aic jcc btn-brown-lighter h-12 w-48 mt-8 lg:mt-0 lg:absolute bottom-28 left-0  text-sm shadow-xl uppercase merienda-bold"
                    @click="showProbeModal = true"
                  >
                    {{$t('Try For Free')}}
                  </button>                  
                </div>
            </div>
          </swiper-slide>      
          <swiper-slide>
            <div class="slide-inner slide-bg-image relative">
                <div class="h-full w-full flex flex-col aic jcc swiper-in">
                  <div class="flex flex-col lg:flex-row items-center justify-center main-container">
                    <img src="/img/slider-2.webp" alt="Slider" class="w-full sm:w-10/12 lg:w-10/12 mb-10 lg:mb-0" width="1200" height="778">
                    <div class="flex flex-col aic jcc text-brown-light -mt-10 lg:mt-0 lg:h-full lg:w-2/12 lg:relative">
                        <div class="flex flex-col aic jcs lg:absolute lg:-left-full">
                          <div data-swiper-parallax="300" class="slide-title">
                            <h1 class="text-xl md:text-2xl lg:text-3xl xl:text-3xl merienda-bold text-brown-light mb-1 md:mb-3 whitespace-nowrap uppercase">Mit frisch Brötchen</h1>
                          </div>
                          <div data-swiper-parallax="400" class="slide-text">
                            <h2 class="text-3xl italianno-regular text-gray-500 subtitle lg:ml-32 mb-auto whitespace-nowrap">lebt es sich besser</h2>
                          </div>
                          <button
                            class="probe-btn-top aic jcc btn-brown-lighter h-12 w-48 shadow-xl uppercase merienda-bold text-sm -mr-60 mt-8"
                            @click="showProbeModal = true"
                          >
                            {{$t('Try For Free')}}
                          </button>
                        </div>
                    </div>
                  </div>
                  <div class="main-container hidden lg:flex aie jcb space-x-6 -mt-12">
                      <div class="flex flex-col w-full max-w-2xl text-gray-700 merienda-regular text-sm">
                          Brötchen Shop online zu bestellen. Denn nur ofenfrisch ist ofenfrisch.
                          Bestell heute und lass Dir die ofenfrischen Brötchen nach Hause liefern!  Probiert es einfach mal aus.
                      </div>
                      <div class="flex w-full border-b-2 border-gray-200"></div>
                  </div>
                  <button
                    class="probe-btn-mid aic jcc btn-brown-lighter h-12 w-48 mt-8 lg:mt-0 lg:absolute bottom-28 left-0  text-sm shadow-xl uppercase merienda-bold"
                    @click="showProbeModal = true"
                  >
                    {{$t('Try For Free')}}
                  </button>                  
                </div>
            </div>
          </swiper-slide>      
          <swiper-slide> 
            <div class="slide-inner slide-bg-image relative">
                <div class="h-full w-full flex flex-col aic jcc swiper-in">
                  <div class="flex flex-col lg:flex-row items-center justify-center main-container">
                    <img src="/img/slider-3.webp" alt="Slider" class="w-full sm:w-10/12 lg:w-10/12 mb-10" width="1400" height="781">
                    <div class="flex flex-col aic jcc text-brown-light lg:h-full lg:w-2/12 lg:relative">
                        <div class="flex flex-col aic jcs lg:absolute lg:-left-full">
                          <div data-swiper-parallax="300" class="slide-title">
                            <h1 class="text-xl md:text-2xl lg:text-3xl xl:text-3xl merienda-bold text-brown-light mb-1 md:mb-3 whitespace-nowrap uppercase">Brötchen Lieferservice,</h1>
                          </div>
                          <div data-swiper-parallax="400" class="slide-text">
                            <h2 class="text-3xl italianno-regular text-gray-500 subtitle lg:ml-32 mb-auto whitespace-nowrap">Kostenlose Probelieferung</h2>
                          </div>
                          <button
                            class="probe-btn-top aic jcc btn-brown-lighter h-12 w-48 shadow-xl uppercase merienda-bold text-sm -mr-60 mt-8"
                            @click="showProbeModal = true"
                          >
                            {{$t('Try For Free')}}
                          </button>
                        </div>
                    </div>
                  </div>
                  <div class="main-container hidden lg:flex aie jcb space-x-6">
                      <div class="flex flex-col w-full max-w-2xl text-gray-700 merienda-regular text-sm">
                          Wenn Sie unsere Dienste testen und morgens beispielsweise ein frisches Brot geliefert bekommen wollen, dann zögern Sie nicht! Geben Sie jetzt Ihre erste Bestellung auf!                      
                      </div>
                      <div class="flex w-full border-b-2 border-gray-200"></div>
                  </div>
                  <button
                    class="probe-btn-mid aic jcc btn-brown-lighter h-12 w-48 mt-8 lg:mt-0 lg:absolute bottom-28 left-0  text-sm shadow-xl uppercase merienda-bold"
                    @click="showProbeModal = true"
                  >
                    {{$t('Try For Free')}}
                  </button>                  
                </div>
            </div>

          </swiper-slide>      
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </Swiper>
    </header>
    <NewContact
      :showContact="showContact"
      @close="showContact = false"
    ></NewContact>
    <Probe
      :showProbeModal="showProbeModal"
      @close="showProbeModal = false"
    >
    </Probe>
    <button
      class="probe-fix z-1 bg-secondary min-w-52 px-5 h-[65px] shadow-2xl hidden md:flex"
      :class="[scrollProbe ? 'show' : 'hide']"
      @click="showProbeModal = true"
      aria-label="Probe Modal"
    >
    <span class="relative h-full w-full flex aic jcc text-white space-x-4">
      <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7" fill="currentColor" viewBox="0 0 24 24">
        <path d="M11 24h-9v-12h9v12zm0-18h-11v4h11v-4zm2 18h9v-12h-9v12zm0-18v4h11v-4h-11zm4.369-6c-2.947 0-4.671 3.477-5.369 5h5.345c3.493 0 3.53-5 .024-5zm-.796 3.621h-2.043c.739-1.121 1.439-1.966 2.342-1.966 1.172 0 1.228 1.966-.299 1.966zm-9.918 1.379h5.345c-.698-1.523-2.422-5-5.369-5-3.506 0-3.469 5 .024 5zm.473-3.345c.903 0 1.603.845 2.342 1.966h-2.043c-1.527 0-1.471-1.966-.299-1.966z"/>
      </svg>
      <!-- <img src="/img/gift-1.webp" alt="probe" class="absolute left-5 w-60p -mt-2" width="300" height="287"> -->
      <span class="text-left merienda-bold tracking-widest text-white text-sm whitespace-nowrap">GRATIS PROBIEREN</span>
    </span>
    </button>
    <button
      class="fixed right-8 bottom-14 z-[60] bg-secondary w-[50px] h-[50px] shadow-2xl md:hidden rounded-lg"
      :class="[scrollProbe ? 'show' : 'hide']"
      @click="showProbeModal = true"
      aria-label="Probe Modal"
    >
    <span class="relative h-full w-full flex aic jcc text-white space-x-4">
      <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
        <path d="M11 24h-9v-12h9v12zm0-18h-11v4h11v-4zm2 18h9v-12h-9v12zm0-18v4h11v-4h-11zm4.369-6c-2.947 0-4.671 3.477-5.369 5h5.345c3.493 0 3.53-5 .024-5zm-.796 3.621h-2.043c.739-1.121 1.439-1.966 2.342-1.966 1.172 0 1.228 1.966-.299 1.966zm-9.918 1.379h5.345c-.698-1.523-2.422-5-5.369-5-3.506 0-3.469 5 .024 5zm.473-3.345c.903 0 1.603.845 2.342 1.966h-2.043c-1.527 0-1.471-1.966-.299-1.966z"/>
      </svg>
    </span>
    </button>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import Probe from "../components/Probe.vue";
// import LangSwitcher from "../components/LangSwitcher.vue";
import NewContact from "../components/NewContact.vue";

export default {
  components: { NewContact, Swiper, SwiperSlide, Probe },
  data() {
    return {
      scrollProbe: false,
      showProbeModal: false,
      showContact: false,
      swiperOption: {
        slidesPerView: 1,
        loop: true,
        speed: 1000,
        parallax: true,
        // autoplay: {
        //     delay: 2500,
        //     disableOnInteraction: false,
        // },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          progress: function() {
              var swiper = this;
              for (var i = 0; i < swiper.slides.length; i++) {
                  var slideProgress = swiper.slides[i].progress;
                  var innerOffset = swiper.width * 0.5;
                  var innerTranslate = slideProgress * innerOffset;
                  swiper.slides[i].querySelector(".slide-inner").style.transform =
                  "translate3d(" + innerTranslate + "px, 0, 0)";
              }      
          },
          touchStart: function() {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = "";
            }
          },
  
          setTransition: function(speed) {
              var swiper = this;
              for (var i = 0; i < swiper.slides.length; i++) {
                  swiper.slides[i].style.transition = speed + "ms";
                  swiper.slides[i].querySelector(".slide-inner").style.transition =
                  speed + "ms";
              }
          }
        }

      },
    };
  },
  methods: {
    // probeModal(){
    //     this.showProbeModal = true;
    //     var bd = document.getElementById('bodyMain')
    //     bd.classList.add("no-scroll")
    // },
    activeRoute(path) {
      return this.$route.path === path;
    },
    scrollFunc() {
      let y = window.scrollY;

      if (y > 500) {
        this.scrollProbe = true;
      }
      if (y < 500) {
        this.scrollProbe = false;
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.scrollFunc);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollFunc);
  },

};
</script>
